.custom-links {
    .textBlockElement {
        h3 {
            background-color: $link-color;
            padding: 20px 10px 10px 20px;
            border-bottom: 2px solid #fff;
            margin-bottom: 0;
            span {
                color: $button-highlight_text;
            }
        }
        .text {
            ul {
                li {
                    display: block;
                    list-style-position: outside;
                    background-color: $link-color;
                    padding: 10px 10px 10px 20px;
                    border-bottom: 2px solid #fff;
                    &:hover {
                        background-color: $hover-color;
                        transition: $transition-standard;
                        a {
                            padding-left: 15px;
                            transition: $transition-standard;
                        }
                    }
                    a {
                        color: $button-highlight_text;
                        text-transform: uppercase;
                        font-size: 12px;
                        &:after {
                            content: '\f061';
                            font-size: 12px;
                            font-family: 'FontAwesome';
                            color: #fff;
                            display: inline-block;
                            position: relative;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}