.hero-container {
  padding-left: 0;
  padding-right: 0;
  .column {
    padding-left: 0;
    padding-right: 0;
    .mediaSlider {
      margin-top: 0;
      margin-bottom: 0;
      p {
        display: none;
      }
      .sn-media-slider {
        .flexslider {
          // set height & width
          // height: 100%; // questionable wheather this is doing anything
          // width: 100%;  // questionable wheather this is doing anything
          // max-width: 1500px;
          max-height: 500px;
          // .slides img {
          //   height: 100%; // questionable wheather this is doing anything
          //   width: 100%;  // questionable wheather this is doing anything
          //   max-width: 1500px;
          //   max-height: 500px;
          //   left: calc((100vw - 1500px)/2) !important;
          // }
          margin: 0;
          .slider-pagination {
            bottom: 10%;
            .paging-item {
              height: 12px;
              width: 12px;
              background: #fff;
              margin: 2px;
            }
            .paging-item.flex-active {
              background: $accent-color;
            }
          }
          .slide-overlay {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.85) 100%);
          }
          .slide-title {
            text-shadow: none;
            font-weight: 900;
          }
          .flex-direction-nav {
            .flex-nav-prev,
            .flex-nav-next {
              @media screen and (max-width: 767px){
                display: none;
              }
            }
          }
          .flex-direction-nav a {
            width: 50px;
            height: 50px;
            line-height: 48px;
            text-shadow: none;
            background: rgba(0,0,0,0.75);
            border-radius: 50%;
            &:before {
              text-shadow: none;
              font-weight: normal;
              padding-left: 10px;
            }
          }
          .flex-direction-nav a.flex-next:before {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.has-mobile-nav {
  .hero-container {
    border-bottom: 3px solid #fff;
    .column {
      .mediaSlider {
        .flexslider {
          .slider-pagination {
            bottom: 10px;
          }
          .flex-viewport {
            .slides {
              .slide {
                .slide-overlay {
                  .slide-title {
                    font-size: 100%;
                  }
                  .slide-description {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}