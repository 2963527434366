.button-link {
  display: inline-block !important;
  border-radius: 4px;
  font-size: 11px !important;
  font-weight: bold !important;
  text-align: center !important;
  padding-top: 7px !important;
  padding-bottom: 6px !important;
  line-height: 1 !important;
  min-width: 80px !important;
  &:after {
    display: none;
  }
}

.button-link.pageEl {
  display: inline-block !important;
  background: transparent;
  padding: 0;

  .pageElement {
    width: auto;
    margin: 0 auto;
    a {
      text-align: center !important;
      &:after {
        display: none;
      }
    }
	}
	&:hover {
		background: transparent !important;
	}
}
