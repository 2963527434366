.user_mode {
  .programs-container {
    .column {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      padding: 20px;
    }
    .pageEl {
      flex: 1;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 80%;
        height: 2px;
        left: 10%;
        top: 0;
        background: $gray-lt;
      }
    }
    .column .pageEl:first-child:after {
      display: none;
    }
    .textBlockElement {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
      padding-top: 40px;

      div[class$='TextImage'] {
        order: 0;
        padding-bottom: 10px;
        a {
          display:block;
          min-height: 150px;
        }
        img {
          max-width: 150px;
          margin: auto;
        }
      }
      h3 {
        order: 1;
        color: $gray-dk;
        text-transform: uppercase;
        font-size: 18px;
      }
      .text {
        order: 2;
        width: 50%;
        font-family: $fontTwo;
        p {
          a {
            @include button-style;
            color: #fff;
            padding: 5px 20px 5px 20px;
            border-radius: 4px;
            // font-size: 11px;
            border-bottom: none;
            &:after {
              content: '\f105';
              font-family: 'FontAwesome';
              font-size: 18px;
              display: inline-block;
              color: #fff;
            }
          }
        }
      }
      .cta-link {
        order: 3;
        padding-top: 25px;
        a {
          font-family:'fontawesome';
          color: white;
          font-size:18px;
          line-height: 25px;
          background: $primary-color;
          padding: 3px 22px;
          border-radius: 4px;
          transition: $transition-standard;
          &:hover {
            background-color: $hover-color;
          }
        }
      }
    }
  }
}

.has-main-nav {
  .user_mode {
    .programs-container {
      .column {
        flex-flow: row nowrap;
        padding: 20px;
      }
      .pageEl {
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 2px;
          height: 80%;
          left: 0;
          top: 10%;
          background: $gray-lt;
        }
      }
    }
  }
}
