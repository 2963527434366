.user_mode {
  .textblock-image-bg {
    min-height: 548px;
    padding-bottom: 50px;
    position: relative;
    &:after {
      content: '';
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0.85) 100%
      );
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .column {
      position: relative;
      display: flex;
      justify-content: center;
      z-index: 100;
    }

    .pageEl {
      align-self: flex-end;
    }
    .textBlockElement {
      h3 {
        font-size: 36px;
        color: white;
        margin-bottom: 7px;
      }
      .text {
        color: white;
        font-size: 18px;
      }
      p:last-of-type {
        padding-top: 20px;
      }
      a:last-of-type {
        color: white;
        background-color: $primary-color;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 4px;
        padding: 10px 20px;
        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }
}
