// Hide edit controls on content load from assets page

.hide-edit-controls {
  .addPageElementTop,
  .pageElementAdmin .elementBar,
  .addPageElement {
    display: none;
  }

  .columnBounds {
    margin: 0;
    border: 0;
    position: relative;

    &:before {
      content: 'Edit on Assets page';
      font-family: sans-serif;
			font-size: 10px;
			line-height: 24px;
      position: absolute;
      display: block;
			height: 24px;
			width: 99%;
      padding-left: 7px;
      top: 0;
      left: 0;
      background: rgba(252, 205, 34, 0.95) url(https://app-assets1.sportngin.com/app_images/elementBar.gif) repeat-x;
      color: black;
      z-index: 20;
      transition: all 0.2s linear;
			box-sizing: border-box;
    }
    &:hover:before {
			padding-left: 10px;
			background-color: #FCCD22ff;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 99%;
      height: calc(100% + 10px);
      top: 0;
      left: 0;
      padding: 7px;
      background-color: #00000032;
      z-index: 10;
      box-sizing: border-box;
    }
  }
}
