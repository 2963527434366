.user_mode {
  .announcements-container {
    background-color: $secondary-color;
    border-bottom: 2px solid white;
    .column {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      min-height: 145px;
    }
    .textBlockElement {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
      h3 {
        text-align: center !important;
        color: white;
        margin-bottom: 10px;
        white-space: nowrap;
      }
      .text {
        text-align: center;
        padding: 0 20px;
        line-height: 1.2;
        p,
        a {
          color: white;
          font-size: 12px;
        }
      }
    }
  }
}

.has-main-nav {
  .user_mode {
    .announcements-container {
      max-height: 60px;
      .column {
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 60px;
        max-height: 60px;
      }
      .textBlockElement {
        flex-flow: row nowrap;
        height: 60px;

        h3 {
          text-align: right !important;
          margin-bottom: 0;
        }
        .text {
          text-align: left;
          padding-left: 25px;
          p {
            font-size: 14px;
            line-height: 1.3;
          }
        }
      }
    }
  }
}
