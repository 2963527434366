.user_mode {
  .social-container {
    background: #eee;
    padding-top: 27px;
    padding-bottom: 34px;
    .column {
      padding-left: 10px;
      padding-right: 10px;
    }
    .sn-media-slider .media-wrapper img {
      width: 100% !important;
      height: auto !important;
      left: 0 !important;
    }
    .sn-media-slider {
      .flex-direction-nav {
        .flex-nav-prev,
        .flex-nav-next {
          @media screen and (max-width: 767px){
            display: none;
          }
        }
      }
      .flex-direction-nav a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-shadow: none;
        background: rgba(0,0,0,0.75);
        border-radius: 50%;
        &:before {
          text-shadow: none;
          font-weight: normal;
          padding-left: 10px;
          font-size: 40px;
        }
      }
      .flex-direction-nav a.flex-next:before {
        padding-right: 10px;
      }
    }

    .aggregatorElement {
      .vevent {
        background: white;
        padding: 30px;
        margin-bottom: 5px;
        border: none;
      }
      h3.aggHeader {
        display: none;
      }
      .dateImage {
        background: $secondary-color;

        .month {
          font-size: 18px;
          font-weight: 900;
          color: white;
        }
        .date {
          font-size: 48px;
          font-weight: 700;
          color: white;
        }
        &:after {
          display: none;
        }
      }
      h5.summary {
        a {
          font-size: 24px;
          color: $secondary-color;
        }
      }
      .details {
        display: flex;
        flex-flow: column wrap;
        padding: 0 0 0 15px;
        color: $gray-dk;
        .description {
          order: 1;
          font-size: 16px;
          margin-top: 0;
          margin-bottom: 15px;
        }
        .tags {
          order: 2;
          display: none;
        }
        .time {
          order: 3;
          abbr {
            text-decoration: none;
          }
          a[title='Download'] {
            display: none;
          }
        }
      }
    }

    .sn-social-media-list {
      background-color: white;
      position: relative;
      padding: 60px 20px 20px 20px;
      margin-top: 63px;
      &:before {
        content: 'Follow Panthers IceDen';
        position: absolute;
        top: 20px;
        left: 20px;
        display: block;
        width: 100%;
        height: 25px;
        color: $secondary-color;
        font-size: 23px;
        font-weight: 900;
      }
      .sn-social-media-icon {
        &:hover {
          background-color: $hover-color;
        }
      }
		}
		.ad-photo {
			.heroPhotoElement {
				background-color:white;
				padding:10px;
			}
		}
  }
}
.has-mobile-nav {
  .user_mode {
    .social-container {
      padding: 0 0 20px 0;
      margin: 0;
      .column-1 {
        padding-left: 0;
        padding-right: 0;
        .mediaSlider {
          margin-top: 0;
          margin-bottom: 0;
          p {
            margin-bottom: 0;
          }
          .slider {
            margin: 0;
          }
        }
      }
      .aggregatorElement {
        .extendedOptions {
          justify-content: center;
        }
      }
      .sn-social-media-list {
        margin-top: 0;
        text-align: center;
        &:before {
          left: 0;
          text-align: center;
        }

        .sn-social-media-icon {
          height: 1.3em;
          width: 1.3em;
          &:before {
            line-height: 2.1em;
            width: 2.1em;
          }
        }
      }
    }
  }
}
