.has-mobile-nav {
  .user_mode {
    .cta-container {
      padding: 0;
      margin: 0;
      .column {
        display: flex;
        flex-flow: column;
        padding: 0;
      }
      .pageEl {
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative;
        margin-bottom: 2px;
        height: 80px;
        transition: height 0.2s linear;
        &:after {
          content: '';
          background-color: $primary-color;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
				}
				&:hover:after {
          background-color: $hover-color;
        }
        &.cta-expand {
          height: 335px;
          .textBlockElement .text a {
            font-size: 18px;
            line-height: 35px;
          }
        }
      }
      .textBlockElement [class$='TextImage'] {
        width: initial !important;
        img {
          display: none;
        }
      }
      .textBlockElement {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 80px;
        position: relative;
        z-index: 1;

        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          margin: 0;
          height: 80px;
          transition: all 0.3s ease-in-out;
          span {
            font-family: $font-primary;
            font-size: 26px;
            line-height: 26px;
            text-transform: uppercase;
            color: #fff;
            font-style: normal;
          }
        }
        .text {
          padding: 0 15px 20px;
          a {
            font-family: $font-primary;
            font-size: 14px;
            color: #fff;
          }
        }
        &:hover {
          h3 {
            margin-top: 0;
            //padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.has-main-nav {
  .user_mode {
    .cta-container {
      padding: 5px 0 3px 5px;
      margin: 0;
      background: #fff;
      .column {
        display: flex;
        flex-flow: row nowrap;
        padding: 0;
      }
      .pageEl {
        flex: 1;
        margin: 0 5px 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative;
        // height: 292px;
        height: 366px;
        max-height: 366px;
        // max-height: 292px;
        //max-width: 366px;
        &:after {
          content: '';
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 40%,
            rgba(0, 0, 0, 0.85) 100%
          );
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
        &.cta-no-image {
          .textBlockElement h3 {
            margin-top: 0;
            background-color: $secondary-color;
            padding-bottom: 10px;
          }
        }
      }
      .textBlockElement [class$='TextImage'] {
        width: initial !important;
        img {
          display: none;
        }
      }
      .textBlockElement {
        margin-top: 0;
        margin-bottom: 0;
        // min-height: 292px;
        min-height: 366px;
				position: relative;
				z-index:1;

        h3 {
          display: flex;
          align-items: center;
          padding: 0 30px 30px 30px;
          margin-top: 275px;
          margin-bottom: 0;
          transition: all 0.1s ease-in-out;
					text-align: left !important;
					position: relative;
					z-index: 100;
          span {
            font-family: $font-primary;
            font-size: 24px;
            line-height: 1.25;
            text-transform: uppercase;
            color: #fff;
            font-style: normal;
          }
        }
        .text {
          padding: 0 30px;
					text-align: left;
					position: relative;
					z-index: 100;
          p {
            padding: 0;
            margin: 0;
            padding-bottom: 30px;
            opacity: 0;
            transition: padding-bottom 0.5s ease, opacity 0.5s ease;
          }
          a {
            font-family: $font-primary;
            font-size: 16px;
            font-weight: 700;
            line-height: 2;
            color: #fff;
          }
        }
        &:hover {
          h3 {
            margin-top: 120px;
						padding-bottom: 10px;
          }
          .text {
            p {
              padding-bottom: 0;
              opacity: 1;
            }
            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
          &:before {
						background-color: rgba($secondary-color,.7);

          }
        }
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
					background-color: rgba($secondary-color,0);
					transition:background-color .2s ease;
					z-index:10;

        }
      }
    }
  }
}
