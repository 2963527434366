// Global Footer
// Edit mode UI
.custom-footer-extra {
  @include edit_ui_element($padding: 10px);
  &:empty {
    display: none;
  }
}

.global-footer {
	background-color: $background-color;
	position:relative;
  .column {
    display: flex;
    padding: 30px;
    .footer-column {
      flex: 0 1 20%;
      padding: 25px;
    }
    .text-logo {
      a {
        img {
          max-width: 60px;
        }
        .cutline {
          order: 0;
          white-space: normal;
          line-height: 1.2;
          background: transparent;
          font-style: normal;
          text-align: center;
          font-size: 10px;
          font-weight: 400;
          color: white;
          padding: 0 0 5px;
          max-width: 110px;
          margin: auto;
        }
      }
    }
  }
  .textBlockElement {
    h3 {
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 1.2em;
    }
    p {
      color: #fff;
      font-size: 12px;
      margin-bottom: 0.25rem;
      a {
        color: #fff;
        font-weight: normal;
        font-size: 12px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .sn-social-media-list {
    position: relative;
    padding-top: 25px;
    .sn-social-media-icon {
      background: $background-color;
      &:hover {
        background: $hover-color;
      }
    }
  }
  .pageElement.sn-social-media-list.dark.md:before {
    content: 'FOLLOW US';
    position: absolute;
    top: 0;
    left: 15px;
    display: block;
    width: 100%;
    height: 25px;
    color: white;
    font-size: 12px;
  }
}

.has-mobile-nav {
  .user_mode {
    .global-footer {
      .column {
        display: flex;
        flex-flow: column wrap;
        .banner-logo {
          .heroPhotoElement {
            a {
              img {
                width: 80%;
              }
            }
          }
        }
        .footer-column {
          flex: unset;
          margin: auto;
          padding: 10px;
          .textBlockElement {
            text-align: center;
            h3 {
              display: none;
            }
          }
          .sn-social-media-list {
            padding-top: 0;
          }
          .pageElement.sn-social-media-list.dark.md:before {
            display: none;
          }
        }
        .text-logo {
          a {
            img {
              width: 30%;
            }
          }
        }
      }
    }
  }
}
