.registration-container {
  padding-left: 0;
  padding-right: 0;
  background: $background-color;
  display: flex;
  flex-flow: row nowrap;
  @media screen and (max-width: 767px) {
    flex-flow: row wrap;
  }
  .column {
    padding-left: 0;
    padding-right: 0;
    border-left: 4px solid #fff;
    @media screen and (max-width: 767px) {
      border-left: none;
      border-bottom: 4px solid #fff;
    }
    &:hover {
      background: $tertiary-color;
      transition: $transition-standard;
    }
  }
  .reg-column {
    .textBlockElement {
      padding: 50px;
      h3 {
        color: #fff;
      }
      p {
        color: #fff;
        .reg-button {
          @include button-style;
          color: #fff;
          padding: 7px 20px 7px 20px;
          border-radius: 4px;
          font-size: 11px;
          border-bottom: none;
        }
      }
    }
  }
  .email-column {
    border-right: 4px solid #fff;
    @media screen and (max-width: 767px) {
      border-right: none;
    }
    .textBlockElement {
      padding: 50px;
      h3 {
        color: #fff;
      }
      p {
        color: #fff;
        .reg-button {
          @include button-style;
          color: #fff;
          padding: 7px 20px 7px 20px;
          border-radius: 4px;
          font-size: 11px;
          border-bottom: none;
        }
      }
    }
  }
}

.birthday-container {
  border: 4px solid #fff;
  background: $background-color;
  background-image: url('../images/party_panther.png');
  background-repeat: no-repeat;
  background-position: right;
  @media screen and (max-width: 1024px) {
    background-size: 130px;
    background-position-y: 120px;
  }
  @media screen and (max-width: 767px) {
    background-position-y: 230px;
    border: none;
  }
  &:hover {
    background: $tertiary-color;
    background-image: url('../images/party_panther.png');
    background-repeat: no-repeat;
    background-position: right;
    transition: $transition-standard;
    @media screen and (max-width: 1024px) {
      background-size: 130px;
      background-position-y: 120px;
    }
    @media screen and (max-width: 767px) {
      background-position-y: 230px;
    }
  }
  .textBlockElement {
    padding: 50px;
    h3 {
      color: #fff;
    }
    p {
      color: #fff;
      margin-left: 20%;
      margin-right: 20%;
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        margin-right: 0;
      }
      .reg-button {
        @include button-style;
        color: #fff;
        padding: 7px 20px 7px 20px;
        border-radius: 4px;
        font-size: 11px;
        border-bottom: none;
      }
    }
  }
}