.ad-section {
  background-image: url('../images/Ad_banner_background.jpg');
  background-size: cover;
  .column {
    .pageEl {
      .heroPhotoElement {
        img {
          width: 75%;
          padding: 25px;
        }
      }
    }
  }
}

.has-mobile-nav {
  .ad-section {
    .column {
      .pageEl {
        .heroPhotoElement {
          img {
            width: 100%;
            padding: 10px;
          }
        }
      }
    }
  }
}
