.user_mode {
  .miturf-container {
    padding-top: 0;
    padding-bottom: 0;
    .column-1 {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 90%;
        bottom: 0;
        right: 5%;
        height: 2px;
        background-color: $gray-lt;
      }
    }
    .pageEl {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .textBlockElement {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      padding-top: 20px;
      padding-bottom: 40px;

      h3 {
        order: 2;
        font-size: 14px;
        color: $gray-dk;
      }
      .text {
        order: 3;
        font-family: $fontTwo;
        p {
          font-size: 14px;
        }
      }
      .leftTextImage,
      .rightTextImage {
        order: 1;
        max-width: 280px;
      }
      a:last-of-type {
        color: white;
        background-color: $primary-color;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-family: $fontOne;
        line-height: 4px;
        padding: 5px 20px;
        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }
}

.has-main-nav {
  .user_mode {
    .miturf-container {
      padding-top: 50px;
      padding-bottom: 50px;
      .column-1 {
        &:after {
          height: 90%;
          bottom: unset;
          top: 5%;
          right: 0;
          width: 2px;
        }
      }
      .textBlockElement {
        padding-top: 0;
        padding-bottom: 0;
        h3 {
          font-size: 18px;
        }
        .leftTextImage,
        .rightTextImage {
          max-width: 315px;
        }
      }
    }
  }
}
