
  .theme-main-nav {
    .theme-nav {
      .custom-main-nav-extra {
        display: flex;
        align-items: center;
				justify-content: flex-end;
				margin-top:17px;
        .pageElement {
          margin: 0;
        }
        .global-registration-btn,
        .global-location-btn {
          margin-left: 10px;
          .pageElement {
            max-width: 80px;
            min-width: 80px;
          }
          a {
            display: block;
            border-radius: 4px;
            font-size: 11px;
            font-weight: bold;
            text-align: center;
            padding-top: 7px;
            padding-bottom: 6px;
						line-height: 1;
						max-width: 80px;
            min-width: 80px;
            &:after {
              display: none;
            }
          }
        }
        .global-panthers-logo {
          max-width: 90px;
          margin-top: -20px;
          margin-left: 11px;
          max-height: 130px;
          .heroPhotoElement {
            display: flex;
            flex-flow: column wrap;
            a {
              order: 1;
              img{
                width: 80%;
              }
            }
            .cutline {
              order: 0;
              white-space: normal;
              line-height: 1;
              background: transparent;
              font-style: normal;
              text-align: center;
              font-size: 10px;
              font-weight: 400;
              color: $secondary-color;
              padding: 0 0 5px;
            }
          }
        }
        .nav-fixed & {
          margin-top: 9px;
        }
      }
    }
  }

