// Config
// Global Layout
// Page Manager
// Page Title

// Variables
$desktop-padding: 15px;
$mobile-padding: 10px;

$content-area-width: 1140px;
$content-area-padding: ($desktop-padding * 2);
$content-area-padding-mobile: ($mobile-padding * 2);
$content-area-background: #fff;

$content-container-margin: 30px;
$enable-full-width-layout: true;
$include-page-title-in-content-area-background: true;

$breakpoint-sm-max: 767px;
$breakpoint-md-min: 768px;
$breakpoint-intermediate-min: 768px;
$breakpoint-intermediate-max: 999px;

// UI Elements
$global-ui-border-size: 1px;
$global-ui-border-color: $gray-lt;

// Global Layout
@mixin adjust-margin-x($multiplier: 1, $responsive: true) {
  margin-left: $desktop-padding * $multiplier;
  margin-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-left: $mobile-padding * $multiplier;
      margin-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin page-element-padding() {
  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max) {
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}
@mixin page-element-margin() {
  margin-top: $desktop-padding;
  margin-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max) {
    margin-top: $mobile-padding;
    margin-bottom: $mobile-padding;
  }
}
@mixin column-padding() {
  padding-left: $desktop-padding;
  padding-right: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max) {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}
@mixin content-container-padding($multiplier: 1, $responsive: true) {
  padding-left: $desktop-padding * $multiplier;
  padding-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-left: $mobile-padding * $multiplier;
      padding-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-container-padding-vert($multiplier: 1, $responsive: true) {
  padding-top: $desktop-padding * $multiplier;
  padding-bottom: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-top: $mobile-padding * $multiplier;
      padding-bottom: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-area-width {
  width: 100%;
  max-width: $content-area-width;
  margin-left: auto;
  margin-right: auto;
}
@mixin content-area-container-style {
  background-color: $content-area-background;
  box-shadow: 0px 2px 9px rgba(black, 0.05);
}
@mixin global-link-style {
  background: $link-color;
  border-radius: 5px;
  transition: background-color $transition-linear;
  -webkit-appearance: none;
  text-decoration: none;
  color: #fff;
  &:focus,
  &:hover {
    background: $link-color-darken-20;
  }
}
@mixin global-link-container($padding: 8px) {
  display: block;
  padding: $padding;
  min-width: 100px;
}
@mixin global-link-text($align: center) {
  text-transform: uppercase;
  font-family: $font-primary;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  text-align: $align;
}
@mixin global-link-icon-style(
  $align: center,
  $color: $primary-color,
  $hover: #fff
) {
  display: flex;
  flex-flow: row;
  justify-content: $align;
  align-items: center;
  &:after {
    color: $color;
    display: inline-block;
    margin-left: 1ch;
    transition: color $transition-linear;
  }
  &:hover:after {
    color: $hover;
  }
}
@mixin global-link-icon-character($character: '\f061', $font: FontAwesome) {
  &:after {
    content: $character;
    font-family: $font;
  }
}

// Fonts - Load
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,700,900|Muli:400,400i,600,700');

// Fonts
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Muli', sans-serif;
$fontOne: $font-primary;
$fontTwo: $font-secondary;
$enable-global-font-smoothing: true;
$page-heading-font: 900 38px/1.25 $fontOne;

// Page Manager
$page-manager-width: 60px;

// Page Title
$page-title-full-width: false;
$page-title-full-width-text: false;
$page-title-container-background: $content-area-background;
$page-title-text-color: $primary-color;
$page-title-font-size: 36px;
$page-title-text-case: null;
$page-title-font-weight: 900;
$page-title-text-align: null;

@mixin page-title-container {
  // site page title container css here
}
@mixin page-title-text {
  //border-bottom: $global-ui-border-size solid $global-ui-border-color;
  // site page title text css here
}
@mixin page-title-image-container {
  // site page title w/image container css here
}

// Account Navigation Options
$account-nav-bottom: false;
$account-nav-mobile-bottom: true;
$account-nav-background: #23282b;
$account-nav-color: #fff;
$account-nav-secondary-color: $account-nav-color;
$account-nav-secondary-color-hover: darken($account-nav-color, 20%);
$account-nav-font-size: 14px;
$account-nav-height-desktop: 40px;
$account-nav-height-mobile: 44px;
$account-nav-z-index: 399;
$account-nav-icon-width: 32px;

$site-tagline-spacing: 15px; // Need this for "_source/sass/_navigation/_network_nav_bar.scss" —— Try to phase this out

// General Navigation
$pages-hidden-in-user-mode: "home"; // Example: "teams", "about us", "home";

// Title Bar Options
$nav-title-bar-height: 25px;
$nav-title-bar-height-fixed: 0.01px;
$nav-title-bar-band-background-color: white;
$nav-title-bar-text-color: #333;
$nav-title-bar-font-family: null;
$nav-title-bar-font-size: 14px;
$nav-title-bar-line-height: 1;
$nav-title-bar-text-case: uppercase;
$nav-title-bar-text-weight: 700;

// Main Navigation Options
$main-nav-width-max: $content-area-width;
$main-nav-height: 130px;
$main-nav-height-fixed: 115px;
$main-nav-band-background-color: white;
$main-nav-flex-alignment: flex-start;
$main-nav-item-padding: 10px;

$main-nav-cascade-styles: true;
$main-nav-child-indicator: false;
$main-nav-child-indicator-cascade-styles: false;
$main-nav-more-plus-buffer: true;
$main-nav-font-family: null;
$main-nav-font-size: 14px;
$main-nav-text-case: uppercase;
$main-nav-text-align: center;
$main-nav-text-weight: bold;
$main-nav-text-color: $gray-dk;
$main-nav-text-color-hover: $primary-color;
$main-nav-text-color-selected: $main-nav-text-color-hover;
$main-nav-text-color-disabled: $gray;
$main-nav-item-background-color: transparent;
$main-nav-item-background-color-hover: transparent;
$main-nav-item-background-color-selected: transparent;
$main-nav-item-background-color-disabled: transparent;

// Dropdown Navigation Options
$dropdown-nav-min-width: 200px;
$dropdown-nav-min-height: 35px;
$dropdown-nav-transition-distance: 20px;
$dropdown-nav-item-padding: $main-nav-item-padding;

$dropdown-nav-cascade-styles: true;
$dropdown-nav-child-indicator: true;
$dropdown-nav-child-indicator-cascade-styles: true;
$dropdown-nav-container-background-color: white;
$dropdown-nav-container-border-size: 5px;
$dropdown-nav-container-border: $dropdown-nav-container-border-size solid
  $primary-color;
$dropdown-nav-text-align: left;
$dropdown-nav-text-case: none;
$dropdown-nav-text-weight: 700;
$dropdown-nav-font-family: null;
$dropdown-nav-font-size: 14px;
$dropdown-nav-line-height: 1.2;
$dropdown-nav-text-color: $main-nav-text-color;
$dropdown-nav-text-color-hover: $main-nav-text-color-hover;
$dropdown-nav-text-color-selected: $dropdown-nav-text-color-hover;
$dropdown-nav-text-color-disabled: $main-nav-text-color-disabled;
$dropdown-nav-item-background-color: transparent;
$dropdown-nav-item-background-color-hover: $main-nav-item-background-color-hover;
$dropdown-nav-item-background-color-selected: $dropdown-nav-item-background-color-hover;
$dropdown-nav-item-background-color-disabled: $main-nav-item-background-color-disabled;

$dropdown-nav-2-container-background-color: $dropdown-nav-container-background-color;
$dropdown-nav-2-container-border-size: null;
$dropdown-nav-2-container-border: null;
$dropdown-nav-2-text-align: null;
$dropdown-nav-2-text-case: null;
$dropdown-nav-2-text-weight: null;
$dropdown-nav-2-font-family: null;
$dropdown-nav-2-font-size: null;
$dropdown-nav-2-line-height: null;
$dropdown-nav-2-text-color: null;
$dropdown-nav-2-text-color-hover: null;
$dropdown-nav-2-text-color-selected: null;
$dropdown-nav-2-text-color-disabled: null;
$dropdown-nav-2-item-background-color: null;
$dropdown-nav-2-item-background-color-hover: null;
$dropdown-nav-2-item-background-color-selected: null;
$dropdown-nav-2-item-background-color-disabled: null;

// Sub Navigation Options
$sub-nav-hide-on-home: true;
$sub-nav-more-plus-buffer: true;
$sub-nav-font-family: null;
$sub-nav-font-size: 12px;
$sub-nav-text-color: $gray-dk;
$sub-nav-text-color-hover: $primary-color;
$sub-nav-text-color-disabled: slategrey;
$sub-nav-item-background-color-disabled: rgba(lightgray, 0.5);
$sub-nav-band-background-color: #ccc;
$sub-nav-height: 50px;
$sub-nav-height-fixed: 25px;
$sub-nav-item-background-color: transparent;
$sub-nav-item-background-color-hover: $gray-lt;
$sub-nav-item-text-case: none;
$sub-nav-item-text-weight: bold;
$sub-nav-item-padding: 10px;
$sub-nav-flex-alignment: flex-start;

// Sub Navigation Title Options
$sub-nav-title-font-family: null;
$sub-nav-title-font-size: $sub-nav-font-size;
$sub-nav-title-height: $sub-nav-height;
$sub-nav-title-text-case: capitalize;
$sub-nav-title-text-weight: bold;
$sub-nav-title-background-color: #ccc;
$sub-nav-title-text-color: $primary-color;
$sub-nav-title-padding: 10px;
$sub-nav-title-divider-content: '\f105';
$sub-nav-title-divider-font: 'fontawesome';

// Sub Navigation Dropdown Options
$sub-nav-dropdown-padding: $sub-nav-item-padding;
$sub-nav-dropdown-font-family: null;
$sub-nav-dropdown-font-size: 12px;
$sub-nav-dropdown-text-case: none;
$sub-nav-dropdown-line-height: 1.2;
$sub-nav-dropdown-min-width: 200px;
$sub-nav-dropdown-min-height: 35px;
$sub-nav-dropdown-transition-distance: 20px;
$sub-nav-dropdown-container-background-color: $sub-nav-band-background-color;
$sub-nav-dropdown-container-border: 0;
$sub-nav-dropdown-text-align: left;

// Search (Desktop) Options
$site-search-height: 26px;
$site-search-spacing: 8px;
$site-search-border-width: 1px;
$site-search-input-font-size: 14px;
$site-search-input-color: #666;
$site-search-input-border-color: rgba(255, 255, 255, 0.1);
$site-search-input-background: rgba(255, 255, 255, 0.8);
$site-search-input-background-focus: rgba(255, 255, 255, 1);
$site-search-submit-color: #fff;
$site-search-submit-border-color: rgba(255, 255, 255, 0.1);
$site-search-submit-background: $link-color-darken-10;
$site-search-submit-background-focus: $link-color-darken-20;
$site-search-submit-font-size: 12px;

// Desktop Navigation Sizes
$desktop-nav-height: $nav-title-bar-height + $main-nav-height + $sub-nav-height;
$desktop-nav-height-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed +
  $sub-nav-height-fixed;
$desktop-nav-height-no-subnav: $nav-title-bar-height + $main-nav-height;
$desktop-nav-height-no-subnav-fixed: $nav-title-bar-height-fixed +
  $main-nav-height-fixed;
$nav-placeholder-height: $desktop-nav-height; // Remove after replacing these in code base
$nav-placeholder-height-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base

// Site Logo Options
$site-logo-size: 195px; // Remove after replacing these in code base
$site-logo-size-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base
$site-logo-width-desktop: $site-logo-size;
$site-logo-height-desktop: 170px;
$site-logo-width-desktop-fixed: $site-logo-size-fixed;
$site-logo-height-desktop-fixed: $site-logo-size-fixed;
$site-logo-width-mobile: 138px;
$site-logo-height-mobile: 73px;
$site-logo-padding: 0;
$site-logo-offset: $site-logo-width-desktop + 22px;
$site-logo-offset-fixed: $site-logo-width-desktop-fixed + 22px;

// Mobile Nav Bar
$mobile-nav-background-color: #fff;
$mobile-nav-toggle-right: true;
$mobile-nav-toggle-width: 44px;
$mobile-nav-toggle-height: 44px;
$mobile-nav-toggle-position: center;
$mobile-nav-open-toggle-position-default: true;
$mobile-nav-height: 105px;
$mobile-nav-align: center;
$mobile-nav-justification: center;

// Footer Colophon
$footer-colophon-se-logo-black-color: false;
$footer-colophon-se-logo-white-color: true;
$footer-colophon-se-logo-white: false;
$footer-colophon-max-width: $content-area-width;
$footer-colophon-band-background-color: $background-color;
$footer-colophon-content-background-color: $background-color;
$footer-colophon-item-padding: 10px;
$footer-colophon-flex-justification: center;
$footer-colophon-flex-align: center;
$footer-colophon-font-size: 12px;
$footer-colophon-line-height: 1.75;
$footer-colophon-text-case: none;
$footer-colophon-text-color: white;
$footer-colophon-link-color: white;
$footer-colophon-link-color-hover: $primary-color;

// Site Background
$site-background-image-full-height: false;

// Page Banner
$site-banner-full-width: true;
$site-banner-full-width-text: false;

$site-banner-padding: 15px;
$site-banner-fixed-min-height: 100px;
$site-banner-font-size: 20px;
$site-banner-font-size-min: 20px;
$site-banner-font-size-max: 72px;
$site-banner-text-color: white;
$site-banner-font-family: null;
$site-banner-line-height: 1;
$site-banner-font-weight: normal;
$site-banner-text-case: null;
$site-banner-text-align: center;

@mixin site-banner-container {
  // site banner container css here
}

$site-banner-headline-text-color: inherit;
$site-banner-headline-font-size: null;
$site-banner-headline-font-family: null;
$site-banner-headline-line-height: null;
$site-banner-headline-font-weight: null;
$site-banner-headline-text-case: null;
$site-banner-headline-text-align: null;
$site-banner-title-text-color: inherit;
$site-banner-title-font-size: inherit;
$site-banner-title-font-family: null;
$site-banner-title-line-height: inherit;
$site-banner-title-font-weight: inherit;
$site-banner-title-text-case: null;
$site-banner-title-text-align: null;
$site-banner-tagline-text-color: inherit;
$site-banner-tagline-font-size: null;
$site-banner-tagline-font-family: null;
$site-banner-tagline-line-height: null;
$site-banner-tagline-font-weight: null;
$site-banner-tagline-text-case: null;
$site-banner-tagline-text-align: null;

// Element Options
$element-options-flex-justification: flex-end;
$element-options-font-size: 12px;
$element-options-text-wieght: 600;
$element-options-text-color: #333;
$element-options-text-color-hover: $hover-color;
$element-options-font-family: $fontTwo;
$element-options-icon-margin: 0.7ch;
$element-options-icon-text-space: 1.5ch;

// Mixins/Functions
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

.no-bt-margin .pageElement {
  margin-bottom: 0;
}
