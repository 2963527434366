.custom-text-block {
  .textBlockElement {
    display: flex;
    flex-flow: column wrap;

    .leftTextImage,
    .rightTextImage {
      order: 1;
    }
    > h3 {
      order: 2;
    }
    .text {
      order: 3;
      font-size: 14px;
      font-family: $fontTwo;
    }
  }
}
